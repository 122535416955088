import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500 500">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="">

	 
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M316.000000,501.000000 
	C210.666687,501.000000 105.833382,501.000000 1.000056,501.000000 
	C1.000037,334.333405 1.000037,167.666794 1.000019,1.000146 
	C167.666565,1.000097 334.333130,1.000097 500.999756,1.000049 
	C500.999847,167.666519 500.999847,334.333038 500.999939,500.999786 
	C439.500000,501.000000 378.000000,501.000000 316.000000,501.000000 
M338.727875,329.888062 
	C338.727875,329.888062 338.901978,329.644043 339.494293,329.652771 
	C340.078033,329.175598 340.661743,328.698456 341.056396,328.222961 
	C341.056396,328.222961 341.238312,328.274567 341.939392,328.215240 
	C349.150787,322.546875 356.362183,316.878510 364.176147,311.000427 
	C375.964600,299.612427 383.197449,286.078094 384.205139,269.443481 
	C385.057831,255.367065 381.465729,243.221436 369.599609,233.627640 
	C362.295807,230.317139 354.995575,226.998688 347.685699,223.701660 
	C345.838165,222.868332 343.959808,222.103333 342.796875,220.863617 
	C347.519348,216.546295 352.241852,212.228989 357.579254,207.665634 
	C361.096741,203.217239 364.614197,198.768845 368.835449,194.214935 
	C372.663910,188.174225 376.492371,182.133530 380.816437,175.634720 
	C385.360809,164.923950 386.947815,154.318222 379.540161,143.391312 
	C377.516357,141.231995 375.777100,138.679245 373.422974,136.976639 
	C357.112915,125.180458 339.286865,126.540802 320.348877,131.267456 
	C318.850830,131.707840 317.352753,132.148239 315.497253,132.486465 
	C315.238251,132.564117 315.061249,132.729630 314.188751,132.954422 
	C302.451019,136.643127 291.012299,140.949936 281.006348,148.921936 
	C281.006348,148.921936 280.766785,149.127609 280.479370,149.154480 
	C280.289246,149.236328 280.169342,149.377792 279.508881,149.919510 
	C278.003479,151.279404 276.498108,152.639297 274.413757,154.332458 
	C270.657196,157.914200 266.900604,161.495941 262.520081,165.324707 
	C257.613068,170.856125 252.706039,176.387543 247.202423,182.206802 
	C242.410507,189.384445 237.618576,196.562073 232.246719,204.078705 
	C224.662445,217.076385 219.194382,230.775055 218.190201,246.913742 
	C218.244049,250.308563 218.297897,253.703384 218.254211,257.862396 
	C218.750107,259.986786 219.246002,262.111145 219.730270,265.086273 
	C220.112549,266.720703 220.494843,268.355103 220.747330,270.541016 
	C221.107834,271.396729 221.468353,272.252411 221.891556,273.795898 
	C222.041626,274.228333 222.191711,274.660736 222.292847,275.505035 
	C222.392746,275.784912 222.581863,275.977081 222.980835,276.829407 
	C226.245850,285.794983 230.890305,293.916046 236.252731,299.827911 
	C235.795074,290.872131 235.287125,280.931915 234.942429,270.214478 
	C234.925064,269.686340 234.907700,269.158234 235.186203,268.455200 
	C235.221420,268.305634 235.316208,268.139221 235.279633,268.009979 
	C235.200302,267.729645 235.053833,267.468384 234.991547,266.388672 
	C239.994125,253.924118 244.996689,241.459579 250.549377,228.622635 
	C257.442871,219.457321 264.336395,210.292007 271.834076,200.857117 
	C284.378540,188.399551 298.012421,177.297775 313.653046,167.704559 
	C318.881592,165.256088 324.038757,162.634933 329.368469,160.431183 
	C332.078918,159.310471 332.438507,157.803314 331.430847,155.443726 
	C329.094299,149.972382 324.589905,147.706467 318.489685,146.645706 
	C317.635162,146.451828 316.780640,146.257935 315.908173,145.477554 
	C315.927521,145.089081 315.946899,144.700623 316.864594,144.229034 
	C318.671997,144.222626 320.505798,144.013062 322.282440,144.243576 
	C332.053741,145.511353 337.669220,148.658936 338.098694,158.777237 
	C336.410431,163.859390 334.722168,168.941544 332.489746,174.417313 
	C330.394043,177.848495 328.298340,181.279678 325.725342,185.064316 
	C320.772491,191.338364 315.819641,197.612396 310.917206,203.942886 
	C310.917206,203.942886 310.954102,203.878311 310.486053,204.094879 
	C310.352844,204.402069 310.219604,204.709244 309.490082,205.325241 
	C307.634857,207.488159 305.779633,209.651077 303.318176,212.115326 
	C296.342896,218.881332 289.346313,225.625641 282.415894,232.437302 
	C281.405640,233.430237 280.711365,234.744629 279.928619,235.963745 
	C279.928619,235.963745 279.966187,235.894577 279.371307,235.822845 
	C278.994171,236.245316 278.617065,236.667786 277.823547,237.436356 
	C278.772583,237.964737 279.664917,238.773407 280.679352,238.978729 
	C292.526917,241.376663 303.651825,245.421188 313.335388,253.285019 
	C313.335388,253.285019 313.605591,253.501114 313.605591,253.501114 
	C313.605591,253.501114 313.941772,253.418915 314.453796,253.979111 
	C315.184998,254.677719 315.916168,255.376312 316.549744,256.381958 
	C316.549744,256.381958 316.758148,256.628357 316.758148,256.628357 
	C316.758148,256.628357 317.081329,256.625610 317.076752,256.825012 
	C317.076752,256.825012 317.263062,256.896301 317.316193,257.578247 
	C325.097076,267.065979 326.016083,277.673706 321.700439,289.135834 
	C322.141541,289.242615 322.582611,289.349426 323.023682,289.456238 
	C324.504120,287.408203 325.984528,285.360199 327.698364,282.801453 
	C327.617371,282.632721 327.536377,282.463959 327.492340,282.276123 
	C327.492340,282.276123 327.529816,282.258423 328.092438,282.761749 
	C329.767761,281.269928 331.443085,279.778076 333.565338,277.979950 
	C333.689392,277.647400 333.813446,277.314850 334.512665,276.632355 
	C339.849915,269.862823 343.596863,262.354187 345.107941,253.809280 
	C345.758301,250.131805 346.395874,246.452057 347.496796,242.942200 
	C347.496796,242.942200 347.969452,243.065048 347.972229,243.957458 
	C348.095001,246.334473 348.217743,248.711472 348.169983,251.686096 
	C348.228668,252.091949 348.287354,252.497803 348.107666,253.774429 
	C348.056396,256.175812 348.005127,258.577179 347.433960,261.508667 
	C345.322937,273.301392 340.272430,283.701599 331.343750,291.676331 
	C323.965088,298.266632 315.837280,304.018158 307.336273,310.205719 
	C294.874817,316.571259 282.364380,322.795319 268.266083,324.791290 
	C265.849579,325.133392 263.462646,325.684082 260.124512,326.058594 
	C255.389816,326.041534 250.655106,326.024445 245.786041,325.693909 
	C245.582565,325.479767 245.385849,325.485748 244.370361,325.340820 
	C241.581436,325.035156 238.771454,324.857422 236.007156,324.402191 
	C222.237564,322.134460 209.872955,316.648712 198.867737,307.332031 
	C196.177643,304.394806 193.283569,301.617554 190.832932,298.492554 
	C180.566025,285.400330 175.870895,270.155945 173.889832,253.881683 
	C173.684814,252.197556 172.916962,250.581924 172.410278,248.934509 
	C172.004959,249.041504 171.599655,249.148499 171.194336,249.255478 
	C171.194336,254.167099 171.194336,259.078735 171.046371,264.835663 
	C172.156342,270.998260 172.890732,277.257263 174.442795,283.306427 
	C180.421951,306.610138 193.176834,324.823029 215.407013,336.546173 
	C222.940979,339.276886 230.474945,342.007568 238.754990,345.047333 
	C240.193176,345.389160 241.615997,345.828186 243.071823,346.058563 
	C276.654755,351.372467 308.549225,346.691071 338.336914,330.083496 
	C338.336914,330.083496 338.511414,330.120026 338.511414,330.120026 
	C338.511414,330.120026 338.428070,329.889435 338.727875,329.888062 
z"/>
<path fill="#1CFFBD" opacity="1.000000" stroke="none" 
	d="
M316.647369,256.074921 
	C315.916168,255.376312 315.184998,254.677719 314.115112,253.592438 
	C313.640381,253.076691 313.481842,252.987717 313.300781,252.938797 
	C303.651825,245.421188 292.526917,241.376663 280.679352,238.978729 
	C279.664917,238.773407 278.772583,237.964737 278.311768,237.218811 
	C279.188721,236.632385 279.577454,236.263489 279.966187,235.894592 
	C279.966187,235.894577 279.928619,235.963745 280.271545,235.951157 
	C285.696594,233.271637 290.778778,230.604706 296.313385,227.959991 
	C302.702148,229.081253 308.731781,229.826935 314.552582,231.363541 
	C322.953552,233.581238 331.181763,236.446487 339.535919,238.854248 
	C341.115295,239.309448 342.938293,238.919464 344.587067,238.919464 
	C344.733368,236.379700 344.830719,234.689590 345.195679,233.237701 
	C346.028320,235.341522 346.593353,237.207123 347.100616,239.438217 
	C347.041626,240.793579 347.040436,241.783463 347.039215,242.773346 
	C346.395874,246.452057 345.758301,250.131805 345.107941,253.809280 
	C343.596863,262.354187 339.849915,269.862823 333.991364,276.952271 
	C333.352844,277.610168 333.235626,277.948181 333.118408,278.286224 
	C331.443085,279.778076 329.767761,281.269928 327.664917,282.113251 
	C327.537354,279.887726 327.905853,278.320129 328.125092,276.731964 
	C329.004242,270.363647 329.837128,263.988983 330.690063,257.594666 
	C325.240540,257.311218 321.251801,257.103760 317.263062,256.896301 
	C317.263062,256.896301 317.076752,256.825012 317.024719,256.619934 
	C316.972687,256.414886 316.647369,256.074921 316.647369,256.074921 
z"/>
<path fill="#2BA6FF" opacity="1.000000" stroke="none" 
	d="
M308.041412,310.141357 
	C315.837280,304.018158 323.965088,298.266632 331.343750,291.676331 
	C340.272430,283.701599 345.322937,273.301392 347.974609,261.434326 
	C350.522461,264.303741 351.990143,262.306091 353.651489,260.884216 
	C356.026886,258.851166 358.516113,256.951019 360.927582,255.376587 
	C357.551544,268.907898 352.133972,281.091156 343.695374,292.154785 
	C339.264282,296.869781 335.133270,301.435486 330.699158,306.147705 
	C327.789551,309.168457 324.630310,311.723206 322.731995,315.006927 
	C320.832214,318.293182 320.192505,322.307861 318.792847,326.286926 
	C312.081207,329.058899 305.577179,331.547211 298.700439,334.154510 
	C295.216370,334.828857 292.105011,335.384216 289.000183,335.578064 
	C292.767487,330.548218 296.618744,325.949097 300.259308,321.188873 
	C302.993591,317.613647 305.457275,313.831421 308.041412,310.141357 
z"/>
<path fill="#3FA0FF" opacity="1.000000" stroke="none" 
	d="
M369.938568,234.223221 
	C381.465729,243.221436 385.057831,255.367065 384.205139,269.443481 
	C383.197449,286.078094 375.964600,299.612427 363.460815,310.948303 
	C358.187927,310.939209 353.630371,310.982208 349.203033,310.720947 
	C351.875916,306.946014 354.418579,303.475372 356.967621,299.989960 
	C356.974030,299.975189 357.001404,299.958221 357.314423,299.869568 
	C357.401215,299.099945 357.175018,298.418976 357.016907,297.399536 
	C358.577148,295.155945 360.476074,293.445312 361.495148,291.313965 
	C367.882935,277.953979 372.056335,264.050568 370.998474,248.550140 
	C370.645782,243.476044 370.292175,238.849625 369.938568,234.223221 
z"/>
<path fill="#1AAEFE" opacity="1.000000" stroke="none" 
	d="
M312.950867,167.819641 
	C298.012421,177.297775 284.378540,188.399551 271.217102,200.750427 
	C270.235901,198.086945 269.864838,195.531082 269.508942,192.973114 
	C268.999664,189.312424 268.501404,185.650177 268.143982,181.689636 
	C271.527435,178.263931 274.765350,175.137161 278.353210,171.970428 
	C283.137939,168.631500 287.572632,165.332535 292.367004,161.989487 
	C298.472839,159.278473 304.219025,156.611542 310.307312,154.145660 
	C311.416534,158.837708 312.183716,163.328674 312.950867,167.819641 
z"/>
<path fill="#1ABAE7" opacity="1.000000" stroke="none" 
	d="
M234.890335,268.630096 
	C234.907700,269.158234 234.925064,269.686340 234.468475,270.584991 
	C233.328735,269.537079 232.539291,268.162170 232.022003,266.691498 
	C230.820511,263.275604 230.756256,258.112488 228.415237,256.728088 
	C223.704590,253.942307 222.482361,250.656662 222.942932,245.473236 
	C223.281418,242.369141 223.618454,239.676895 224.316193,237.026276 
	C229.445496,239.644379 231.271667,239.024155 233.107880,234.007462 
	C234.807831,229.363037 236.372498,224.669083 238.135986,219.679688 
	C240.519684,215.576965 242.764145,211.791519 245.401367,208.011841 
	C246.838211,209.515533 248.592209,210.922852 248.797104,212.527908 
	C249.492996,217.979263 249.641617,223.500504 249.999252,228.995041 
	C244.996689,241.459579 239.994125,253.924118 234.969543,267.033081 
	C234.953506,267.996552 234.934448,268.314087 234.890335,268.630096 
z"/>
<path fill="#26A9FF" opacity="1.000000" stroke="none" 
	d="
M369.769104,233.925430 
	C370.292175,238.849625 370.645782,243.476044 370.642090,248.591812 
	C369.397949,250.281784 368.615417,251.580673 367.599182,252.659439 
	C366.155853,254.191605 364.540588,255.561813 362.996033,256.611603 
	C362.845490,254.953674 362.697693,253.685379 362.549866,252.417068 
	C362.224121,252.423279 361.898376,252.429489 361.572632,252.435699 
	C361.367340,253.288513 361.162018,254.141327 360.956726,254.994141 
	C358.516113,256.951019 356.026886,258.851166 353.651489,260.884216 
	C351.990143,262.306091 350.522461,264.303741 348.234558,261.169250 
	C348.005127,258.577179 348.056396,256.175812 348.387878,253.190781 
	C348.558929,252.100922 348.449707,251.594696 348.340485,251.088486 
	C348.217743,248.711472 348.095001,246.334473 347.981750,243.150116 
	C347.713623,241.252777 347.436035,240.162750 347.158417,239.072739 
	C346.593353,237.207123 346.028320,235.341522 345.188477,232.864868 
	C343.939606,228.588104 342.965576,224.922379 342.018372,221.267578 
	C342.045197,221.278473 342.095612,221.306931 342.095612,221.306931 
	C343.959808,222.103333 345.838165,222.868332 347.685699,223.701660 
	C354.995575,226.998688 362.295807,230.317139 369.769104,233.925430 
z"/>
<path fill="#26A9FF" opacity="1.000000" stroke="none" 
	d="
M338.387756,329.900330 
	C308.549225,346.691071 276.654755,351.372467 243.071823,346.058563 
	C241.615997,345.828186 240.193176,345.389160 238.394821,344.527405 
	C238.928711,343.215302 239.694717,342.062531 240.738449,341.691956 
	C245.886658,339.864044 251.112045,338.893982 256.659332,340.036285 
	C260.021942,340.728729 263.531494,340.707794 267.446472,341.006042 
	C273.566528,340.999695 279.272919,341.493469 284.847351,340.847839 
	C290.303406,340.215912 295.625031,338.422913 301.412048,337.095642 
	C303.720001,336.607788 305.728424,336.419189 307.504608,335.675751 
	C315.331238,332.400024 323.090881,328.964355 331.090393,325.928040 
	C333.041840,326.280212 334.828217,326.038574 336.499054,326.373444 
	C338.139893,326.702301 339.663361,327.616974 341.238312,328.274567 
	C341.238312,328.274567 341.056396,328.222961 340.832886,328.171875 
	C340.040253,328.628571 339.471100,329.136292 338.901978,329.644043 
	C338.901978,329.644043 338.727875,329.888062 338.571167,329.904846 
	C338.414459,329.921600 338.387756,329.900330 338.387756,329.900330 
z"/>
<path fill="#46E886" opacity="1.000000" stroke="none" 
	d="
M379.693878,144.026474 
	C386.947815,154.318222 385.360809,164.923950 380.160950,175.836273 
	C377.866028,177.295547 376.228943,178.556274 374.586395,179.809921 
	C373.452209,180.675583 372.310486,181.531418 371.172119,182.391632 
	C371.117279,180.924469 370.969269,179.452438 371.045807,177.992172 
	C371.085602,177.232834 371.521881,176.494293 371.976654,175.171875 
	C367.786011,173.680893 363.810272,172.266357 359.911804,170.502075 
	C364.148438,161.216370 367.813904,152.194443 365.305725,142.017746 
	C370.382263,142.682693 375.038086,143.354584 379.693878,144.026474 
z"/>
<path fill="#1AAEFE" opacity="1.000000" stroke="none" 
	d="
M245.920410,326.007385 
	C250.655106,326.024445 255.389816,326.041534 260.825073,326.342773 
	C262.575226,326.940308 263.624786,327.253693 265.639008,327.855103 
	C263.914490,329.677032 262.582672,330.956451 261.402222,332.362915 
	C259.870575,334.187836 258.459381,336.113861 256.585693,337.945282 
	C252.277954,337.361847 248.270767,337.234894 244.506592,336.212952 
	C236.597824,334.065704 228.816666,331.448425 220.661285,328.878418 
	C214.434753,325.091339 208.296097,321.770630 202.708603,317.694366 
	C199.055969,315.029663 196.202362,311.269714 193.334900,307.968140 
	C195.425110,307.956543 197.171249,307.977142 198.917404,307.997772 
	C209.872955,316.648712 222.237564,322.134460 236.007156,324.402191 
	C238.771454,324.857422 241.581436,325.035156 244.892471,325.596924 
	C245.572998,325.937805 245.741608,325.989258 245.920410,326.007385 
z"/>
<path fill="#19FEB3" opacity="1.000000" stroke="none" 
	d="
M338.290131,157.999954 
	C337.669220,148.658936 332.053741,145.511353 322.282440,144.243576 
	C320.505798,144.013062 318.671997,144.222626 316.002808,144.142487 
	C313.096191,143.958908 311.051361,143.861877 309.003967,143.402863 
	C309.010590,142.078796 309.019806,141.116745 309.446289,140.106781 
	C311.172394,139.888428 312.565369,139.949890 313.776581,139.510468 
	C322.583862,136.315140 331.734650,136.824524 340.824280,137.011993 
	C348.416046,137.168579 355.049561,141.727844 354.898834,146.924240 
	C354.742981,152.297379 353.684479,157.644302 352.640320,162.996323 
	C347.603241,161.326614 342.946686,159.663284 338.290131,157.999954 
z"/>
<path fill="#23F7A0" opacity="1.000000" stroke="none" 
	d="
M326.202637,184.710861 
	C328.298340,181.279678 330.394043,177.848495 333.033203,174.417084 
	C336.217133,178.640717 340.139526,180.242325 344.942139,180.337311 
	C342.358887,184.491684 339.803345,188.295364 337.143402,192.254410 
	C339.374023,194.987701 341.798828,194.413040 343.288025,192.070572 
	C347.195892,185.923401 352.239014,187.359283 357.554260,189.306946 
	C360.928589,190.543381 364.341858,191.674438 367.703644,192.942917 
	C367.980225,193.047256 367.995178,193.844925 368.131653,194.320465 
	C364.614197,198.768845 361.096741,203.217239 357.122498,207.466949 
	C355.704773,206.503586 354.858063,205.378174 353.764557,205.031830 
	C345.713379,202.481674 337.708008,199.495300 329.027222,200.646011 
	C329.477142,199.605591 329.788483,198.818542 330.318268,198.227219 
	C337.525238,190.182953 337.165619,188.826843 326.687225,184.806992 
	C326.535706,184.748871 326.364594,184.741791 326.202637,184.710861 
z"/>
<path fill="#26A9FF" opacity="1.000000" stroke="none" 
	d="
M256.996338,337.996338 
	C258.459381,336.113861 259.870575,334.187836 261.402222,332.362915 
	C262.582672,330.956451 263.914490,329.677032 265.639008,327.855103 
	C263.624786,327.253693 262.575226,326.940308 261.293823,326.382690 
	C263.462646,325.684082 265.849579,325.133392 268.266083,324.791290 
	C282.364380,322.795319 294.874817,316.571259 307.688843,310.173523 
	C305.457275,313.831421 302.993591,317.613647 300.259308,321.188873 
	C296.618744,325.949097 292.767487,330.548218 288.608917,335.643738 
	C281.153412,336.723938 274.095734,337.376923 266.581543,338.021027 
	C263.082184,338.006866 260.039246,338.001587 256.996338,337.996338 
z"/>
<path fill="#30F197" opacity="1.000000" stroke="none" 
	d="
M368.483582,194.267700 
	C367.995178,193.844925 367.980225,193.047256 367.703644,192.942917 
	C364.341858,191.674438 360.928589,190.543381 357.554260,189.306946 
	C352.239014,187.359283 347.195892,185.923401 343.288025,192.070572 
	C341.798828,194.413040 339.374023,194.987701 337.143402,192.254410 
	C339.803345,188.295364 342.358887,184.491684 345.086670,180.027893 
	C346.852112,176.566956 348.445312,173.766083 350.356842,170.927917 
	C351.133514,170.250687 351.591827,169.610748 352.246887,168.959930 
	C352.443604,168.949066 352.837524,168.957870 353.029846,169.259186 
	C354.935486,172.504120 357.304749,172.088959 359.834534,170.851822 
	C363.810272,172.266357 367.786011,173.680893 371.976654,175.171875 
	C371.521881,176.494293 371.085602,177.232834 371.045807,177.992172 
	C370.969269,179.452438 371.117279,180.924469 371.172119,182.391632 
	C372.310486,181.531418 373.452209,180.675583 374.586395,179.809921 
	C376.228943,178.556274 377.866028,177.295547 379.913147,176.065338 
	C376.492371,182.133530 372.663910,188.174225 368.483582,194.267700 
z"/>
<path fill="#1AB6F2" opacity="1.000000" stroke="none" 
	d="
M250.274323,228.808838 
	C249.641617,223.500504 249.492996,217.979263 248.797104,212.527908 
	C248.592209,210.922852 246.838211,209.515533 245.545776,207.705383 
	C250.866165,200.594971 256.434906,193.796768 262.301483,186.847961 
	C264.399017,185.127777 266.198730,183.558197 267.998444,181.988617 
	C268.501404,185.650177 268.999664,189.312424 269.508942,192.973114 
	C269.864838,195.531082 270.235901,198.086945 270.915039,200.885208 
	C264.336395,210.292007 257.442871,219.457321 250.274323,228.808838 
z"/>
<path fill="#1BFBA8" opacity="1.000000" stroke="none" 
	d="
M328.990204,200.990372 
	C337.708008,199.495300 345.713379,202.481674 353.764557,205.031830 
	C354.858063,205.378174 355.704773,206.503586 356.815063,207.589981 
	C352.241852,212.228989 347.519348,216.546295 342.446228,221.085266 
	C342.095612,221.306931 342.045197,221.278473 341.637695,221.168839 
	C331.831848,219.043839 322.433441,217.028473 313.174744,214.707306 
	C315.252167,212.277390 317.189911,210.153290 319.471039,207.988983 
	C322.872986,205.629288 325.931610,203.309830 328.990204,200.990372 
z"/>
<path fill="#1AAEFE" opacity="1.000000" stroke="none" 
	d="
M262.003662,186.998566 
	C256.434906,193.796768 250.866165,200.594971 245.153015,207.699615 
	C242.764145,211.791519 240.519684,215.576965 237.747971,219.680542 
	C234.466766,218.644501 231.712830,217.290329 228.977020,215.554764 
	C230.272324,211.362152 231.549484,207.550934 232.826660,203.739716 
	C237.618576,196.562073 242.410507,189.384445 247.874115,182.116302 
	C253.031754,183.683411 257.517700,185.340988 262.003662,186.998566 
z"/>
<path fill="#3FA0FF" opacity="1.000000" stroke="none" 
	d="
M192.990875,308.000336 
	C196.202362,311.269714 199.055969,315.029663 202.708603,317.694366 
	C208.296097,321.770630 214.434753,325.091339 220.658203,329.247559 
	C219.890564,330.822784 218.777817,331.857819 217.725647,332.951172 
	C216.781235,333.932495 215.903473,334.977966 214.996307,335.995178 
	C193.176834,324.823029 180.421951,306.610138 174.442795,283.306427 
	C172.890732,277.257263 172.156342,270.998260 171.480957,264.505188 
	C173.930740,270.890381 175.083740,278.037079 178.172424,284.216187 
	C182.330597,292.534790 187.980957,300.107483 192.990875,308.000336 
z"/>
<path fill="#20C1E1" opacity="1.000000" stroke="none" 
	d="
M222.941483,245.885056 
	C222.482361,250.656662 223.704590,253.942307 228.415237,256.728088 
	C230.756256,258.112488 230.820511,263.275604 232.022003,266.691498 
	C232.539291,268.162170 233.328735,269.537079 234.386841,270.973602 
	C235.287125,280.931915 235.795074,290.872131 236.252731,299.827911 
	C230.890305,293.916046 226.245850,285.794983 222.917206,276.255035 
	C222.778870,275.401306 222.608276,275.205475 222.341782,275.093170 
	C222.191711,274.660736 222.041626,274.228333 221.913849,273.151733 
	C221.583130,271.668213 221.230133,270.828888 220.877136,269.989532 
	C220.494843,268.355103 220.112549,266.720703 219.809601,264.254456 
	C219.376541,261.314514 218.864136,259.206360 218.351746,257.098206 
	C218.297897,253.703384 218.244049,250.308563 218.655258,246.456894 
	C220.394043,245.961716 221.667755,245.923386 222.941483,245.885056 
z"/>
<path fill="#46E886" opacity="1.000000" stroke="none" 
	d="
M353.020813,163.002701 
	C353.684479,157.644302 354.742981,152.297379 354.898834,146.924240 
	C355.049561,141.727844 348.416046,137.168579 340.824280,137.011993 
	C331.734650,136.824524 322.583862,136.315140 313.776581,139.510468 
	C312.565369,139.949890 311.172394,139.888428 309.061432,140.064880 
	C301.800140,142.383911 295.340973,144.696884 288.563599,147.177872 
	C285.935364,147.849655 283.625336,148.353439 281.315338,148.857239 
	C291.012299,140.949936 302.451019,136.643127 314.742798,132.943253 
	C315.514008,132.868195 315.699951,132.753723 315.854706,132.588638 
	C317.352753,132.148239 318.850830,131.707840 321.013275,131.510086 
	C323.773407,131.552719 326.623444,130.384369 327.830841,131.324829 
	C332.405853,134.888351 337.277496,132.870102 342.195984,133.347626 
	C343.375763,134.121399 344.353088,134.814484 345.405792,134.959152 
	C352.604736,135.948654 357.212860,139.571198 358.555145,146.390045 
	C359.884979,153.145905 357.923828,159.378616 353.792725,164.859222 
	C353.362976,164.135834 353.191895,163.569260 353.020813,163.002701 
z"/>
<path fill="#3FA0FF" opacity="1.000000" stroke="none" 
	d="
M281.160828,148.889587 
	C283.625336,148.353439 285.935364,147.849655 289.013336,147.212280 
	C294.769684,146.419250 299.758026,145.759827 305.040894,145.418579 
	C308.865631,145.845886 312.395874,145.954971 315.926117,146.064056 
	C316.780640,146.257935 317.635162,146.451828 318.690002,147.192444 
	C315.915314,149.807663 312.940277,151.876129 309.965240,153.944595 
	C304.219025,156.611542 298.472839,159.278473 292.169495,161.706314 
	C286.072449,158.977875 280.532593,156.488541 274.992706,153.999207 
	C276.498108,152.639297 278.003479,151.279404 279.960602,149.750397 
	C280.599762,149.484299 280.717896,149.333069 280.766785,149.127609 
	C280.766785,149.127609 281.006348,148.921936 281.160828,148.889587 
z"/>
<path fill="#26A9FF" opacity="1.000000" stroke="none" 
	d="
M262.301483,186.847961 
	C257.517700,185.340988 253.031754,183.683411 248.172409,181.972382 
	C252.706039,176.387543 257.613068,170.856125 263.149109,165.340439 
	C268.519867,167.574249 273.261536,169.792313 278.003235,172.010391 
	C274.765350,175.137161 271.527435,178.263931 268.143982,181.689651 
	C266.198730,183.558197 264.399017,185.127777 262.301483,186.847961 
z"/>
<path fill="#57E582" opacity="1.000000" stroke="none" 
	d="
M342.028442,133.013184 
	C337.277496,132.870102 332.405853,134.888351 327.830841,131.324829 
	C326.623444,130.384369 323.773407,131.552719 321.404114,131.534470 
	C339.286865,126.540802 357.112915,125.180458 373.422974,136.976639 
	C375.777100,138.679245 377.516357,141.231995 379.617004,143.708893 
	C375.038086,143.354584 370.382263,142.682693 365.022003,141.859695 
	C358.647339,134.299973 350.946899,132.095459 342.028442,133.013184 
z"/>
<path fill="#2FA4FF" opacity="1.000000" stroke="none" 
	d="
M215.201660,336.270691 
	C215.903473,334.977966 216.781235,333.932495 217.725647,332.951172 
	C218.777817,331.857819 219.890564,330.822784 220.980026,329.393250 
	C228.816666,331.448425 236.597824,334.065704 244.506592,336.212952 
	C248.270767,337.234894 252.277954,337.361847 256.585693,337.945251 
	C260.039246,338.001587 263.082184,338.006866 266.566406,338.392273 
	C266.996796,339.517426 266.985901,340.262360 266.974976,341.007324 
	C263.531494,340.707794 260.021942,340.728729 256.659332,340.036285 
	C251.112045,338.893982 245.886658,339.864044 240.738449,341.691956 
	C239.694717,342.062531 238.928711,343.215302 238.021790,344.372864 
	C230.474945,342.007568 222.940979,339.276886 215.201660,336.270691 
z"/>
<path fill="#2FA4FF" opacity="1.000000" stroke="none" 
	d="
M278.353210,171.970428 
	C273.261536,169.792313 268.519867,167.574249 263.461121,165.216919 
	C266.900604,161.495941 270.657196,157.914200 274.703247,154.165833 
	C280.532593,156.488541 286.072449,158.977875 291.809845,161.750397 
	C287.572632,165.332535 283.137939,168.631500 278.353210,171.970428 
z"/>
<path fill="#1BFBA8" opacity="1.000000" stroke="none" 
	d="
M352.640320,162.996323 
	C353.191895,163.569260 353.362976,164.135834 353.738281,165.200043 
	C353.574158,166.784424 353.205841,167.871140 352.837524,168.957870 
	C352.837524,168.957870 352.443604,168.949066 351.925323,169.007812 
	C350.950867,169.699448 350.494659,170.332321 350.038483,170.965210 
	C348.445312,173.766083 346.852112,176.566956 345.114380,179.677216 
	C340.139526,180.242325 336.217133,178.640717 333.305298,174.220276 
	C334.722168,168.941544 336.410431,163.859390 338.194397,158.388596 
	C342.946686,159.663284 347.603241,161.326614 352.640320,162.996323 
z"/>
<path fill="#26A9FF" opacity="1.000000" stroke="none" 
	d="
M310.307312,154.145660 
	C312.940277,151.876129 315.915314,149.807663 318.945251,147.394836 
	C324.589905,147.706467 329.094299,149.972382 331.430847,155.443726 
	C332.438507,157.803314 332.078918,159.310471 329.368469,160.431183 
	C324.038757,162.634933 318.881592,165.256088 313.301941,167.762100 
	C312.183716,163.328674 311.416534,158.837708 310.307312,154.145660 
z"/>
<path fill="#30F197" opacity="1.000000" stroke="none" 
	d="
M329.027222,200.646011 
	C325.931610,203.309830 322.872986,205.629288 319.145477,207.843643 
	C315.939972,206.454514 313.403381,205.170486 310.866791,203.886444 
	C315.819641,197.612396 320.772491,191.338364 325.963989,184.887589 
	C326.364594,184.741791 326.535706,184.748871 326.687225,184.806992 
	C337.165619,188.826843 337.525238,190.182953 330.318268,198.227219 
	C329.788483,198.818542 329.477142,199.605591 329.027222,200.646011 
z"/>
<path fill="#1AB6F2" opacity="1.000000" stroke="none" 
	d="
M193.334915,307.968140 
	C187.980957,300.107483 182.330597,292.534790 178.172424,284.216187 
	C175.083740,278.037079 173.930740,270.890381 171.554932,264.082520 
	C171.194336,259.078735 171.194336,254.167099 171.194336,249.255478 
	C171.599655,249.148499 172.004959,249.041504 172.410278,248.934509 
	C172.916962,250.581924 173.684814,252.197556 173.889832,253.881683 
	C175.870895,270.155945 180.566025,285.400330 190.832932,298.492554 
	C193.283569,301.617554 196.177643,304.394806 198.892578,307.664917 
	C197.171249,307.977142 195.425110,307.956543 193.334915,307.968140 
z"/>
<path fill="#2FA4FF" opacity="1.000000" stroke="none" 
	d="
M341.588867,328.244904 
	C339.663361,327.616974 338.139893,326.702301 336.499054,326.373444 
	C334.828217,326.038574 333.041840,326.280212 331.287476,325.682190 
	C337.203552,320.405060 343.138184,315.715118 349.072784,311.025208 
	C353.630371,310.982208 358.187927,310.939209 363.159546,311.053162 
	C356.362183,316.878510 349.150787,322.546875 341.588867,328.244904 
z"/>
<path fill="#35FFCA" opacity="1.000000" stroke="none" 
	d="
M317.289612,257.237274 
	C321.251801,257.103760 325.240540,257.311218 330.690063,257.594666 
	C329.837128,263.988983 329.004242,270.363647 328.125092,276.731964 
	C327.905853,278.320129 327.537354,279.887726 327.383606,281.861572 
	C327.529816,282.258423 327.492340,282.276123 327.206177,282.266754 
	C327.101654,282.609009 327.283295,282.960602 327.464935,283.312195 
	C325.984528,285.360199 324.504120,287.408203 323.023682,289.456238 
	C322.582611,289.349426 322.141541,289.242615 321.700439,289.135834 
	C326.016083,277.673706 325.097076,267.065979 317.289612,257.237274 
z"/>
<path fill="#57E582" opacity="1.000000" stroke="none" 
	d="
M295.860931,227.937775 
	C290.778778,230.604706 285.696594,233.271637 280.242645,235.924423 
	C280.711365,234.744629 281.405640,233.430237 282.415894,232.437302 
	C289.346313,225.625641 296.342896,218.881332 303.678528,212.314453 
	C305.360474,214.354309 306.682068,216.195053 307.738251,218.233093 
	C303.602203,221.599533 299.731567,224.768661 295.860931,227.937775 
z"/>
<path fill="#4BB2FF" opacity="1.000000" stroke="none" 
	d="
M222.942932,245.473236 
	C221.667755,245.923386 220.394043,245.961716 218.739166,246.003983 
	C219.194382,230.775055 224.662445,217.076385 232.536682,203.909210 
	C231.549484,207.550934 230.272324,211.362152 228.709137,215.814667 
	C226.933899,223.298843 225.444702,230.141739 223.955505,236.984634 
	C223.618454,239.676895 223.281418,242.369141 222.942932,245.473236 
z"/>
<path fill="#46E886" opacity="1.000000" stroke="none" 
	d="
M308.003662,218.035767 
	C306.682068,216.195053 305.360474,214.354309 303.981659,212.163788 
	C305.779633,209.651077 307.634857,207.488159 309.991486,205.031357 
	C310.739502,204.500946 310.893250,204.214569 310.954102,203.878311 
	C310.954102,203.878311 310.917206,203.942886 310.891998,203.914673 
	C313.403381,205.170486 315.939972,206.454514 318.802124,207.883881 
	C317.189911,210.153290 315.252167,212.277390 312.872375,214.847107 
	C310.954803,216.207062 309.479218,217.121414 308.003662,218.035767 
z"/>
<path fill="#92D0FF" opacity="1.000000" stroke="none" 
	d="
M315.917145,145.770813 
	C312.395874,145.954971 308.865631,145.845886 305.378052,145.239273 
	C306.615967,144.416122 307.811249,144.090500 309.006500,143.764862 
	C311.051361,143.861877 313.096191,143.958908 315.553650,144.184052 
	C315.946899,144.700623 315.927521,145.089081 315.917145,145.770813 
z"/>
<path fill="#4BB2FF" opacity="1.000000" stroke="none" 
	d="
M218.302979,257.480286 
	C218.864136,259.206360 219.376541,261.314514 219.815430,263.829102 
	C219.246002,262.111145 218.750107,259.986786 218.302979,257.480286 
z"/>
<path fill="#2FA4FF" opacity="1.000000" stroke="none" 
	d="
M339.198120,329.648407 
	C339.471100,329.136292 340.040253,328.628571 340.927429,328.171051 
	C340.661743,328.698456 340.078033,329.175598 339.198120,329.648407 
z"/>
<path fill="#2BA6FF" opacity="1.000000" stroke="none" 
	d="
M347.100616,239.438217 
	C347.436035,240.162750 347.713623,241.252777 347.980347,242.703918 
	C347.969452,243.065048 347.496796,242.942200 347.268005,242.857773 
	C347.040436,241.783463 347.041626,240.793579 347.100616,239.438217 
z"/>
<path fill="#4BB2FF" opacity="1.000000" stroke="none" 
	d="
M220.812225,270.265259 
	C221.230133,270.828888 221.583130,271.668213 221.882507,272.807861 
	C221.468353,272.252411 221.107834,271.396729 220.812225,270.265259 
z"/>
<path fill="#2BA6FF" opacity="1.000000" stroke="none" 
	d="
M348.255249,251.387299 
	C348.449707,251.594696 348.558929,252.100922 348.507080,252.755402 
	C348.287354,252.497803 348.228668,252.091949 348.255249,251.387299 
z"/>
<path fill="#20C1E1" opacity="1.000000" stroke="none" 
	d="
M235.038269,268.542664 
	C234.934448,268.314087 234.953506,267.996552 234.941177,267.438416 
	C235.053833,267.468384 235.200302,267.729645 235.279633,268.009979 
	C235.316208,268.139221 235.221420,268.305634 235.038269,268.542664 
z"/>
<path fill="#57E582" opacity="1.000000" stroke="none" 
	d="
M279.668762,235.858719 
	C279.577454,236.263489 279.188721,236.632385 278.519958,237.045776 
	C278.617065,236.667786 278.994171,236.245316 279.668762,235.858719 
z"/>
<path fill="#1CFFBD" opacity="1.000000" stroke="none" 
	d="
M327.581665,283.056824 
	C327.283295,282.960602 327.101654,282.609009 327.187683,282.276306 
	C327.536377,282.463959 327.617371,282.632721 327.581665,283.056824 
z"/>
<path fill="#35FFCA" opacity="1.000000" stroke="none" 
	d="
M316.598572,256.228455 
	C316.647369,256.074921 316.972687,256.414886 317.027008,256.520264 
	C317.081329,256.625610 316.758148,256.628357 316.758148,256.628357 
	C316.758148,256.628357 316.549744,256.381958 316.598572,256.228455 
z"/>
<path fill="#35FFCA" opacity="1.000000" stroke="none" 
	d="
M333.341858,278.133087 
	C333.235626,277.948181 333.352844,277.610168 333.703796,277.127258 
	C333.813446,277.314850 333.689392,277.647400 333.341858,278.133087 
z"/>
<path fill="#4BB2FF" opacity="1.000000" stroke="none" 
	d="
M222.317322,275.299103 
	C222.608276,275.205475 222.778870,275.401306 222.856888,275.881104 
	C222.581863,275.977081 222.392746,275.784912 222.317322,275.299103 
z"/>
<path fill="#30F197" opacity="1.000000" stroke="none" 
	d="
M310.720093,203.986603 
	C310.893250,204.214569 310.739502,204.500946 310.289612,204.876953 
	C310.219604,204.709244 310.352844,204.402069 310.720093,203.986603 
z"/>
<path fill="#26A9FF" opacity="1.000000" stroke="none" 
	d="
M245.853226,325.850647 
	C245.741608,325.989258 245.572998,325.937805 245.305237,325.782410 
	C245.385849,325.485748 245.582565,325.479767 245.853226,325.850647 
z"/>
<path fill="#57E582" opacity="1.000000" stroke="none" 
	d="
M315.675964,132.537552 
	C315.699951,132.753723 315.514008,132.868195 315.131561,132.957550 
	C315.061249,132.729630 315.238251,132.564117 315.675964,132.537552 
z"/>
<path fill="#35FFCA" opacity="1.000000" stroke="none" 
	d="
M313.318085,253.111908 
	C313.481842,252.987717 313.640381,253.076691 313.859070,253.312332 
	C313.941772,253.418915 313.605591,253.501114 313.605591,253.501114 
	C313.605591,253.501114 313.335388,253.285019 313.318085,253.111908 
z"/>
<path fill="#2FA4FF" opacity="1.000000" stroke="none" 
	d="
M338.362335,329.991913 
	C338.387756,329.900330 338.414459,329.921600 338.421265,329.905518 
	C338.428070,329.889435 338.511414,330.120026 338.511414,330.120026 
	C338.511414,330.120026 338.336914,330.083496 338.362335,329.991913 
z"/>
<path fill="#46E886" opacity="1.000000" stroke="none" 
	d="
M280.623077,149.141052 
	C280.717896,149.333069 280.599762,149.484299 280.265991,149.580078 
	C280.169342,149.377792 280.289246,149.236328 280.623077,149.141052 
z"/>
<path fill="#19FEB3" opacity="1.000000" stroke="none" 
	d="
M307.738251,218.233093 
	C309.479218,217.121414 310.954803,216.207062 312.732727,215.152908 
	C322.433441,217.028473 331.831848,219.043839 341.610870,221.157928 
	C342.965576,224.922379 343.939606,228.588104 344.920837,232.626648 
	C344.830719,234.689590 344.733368,236.379700 344.587067,238.919464 
	C342.938293,238.919464 341.115295,239.309448 339.535919,238.854248 
	C331.181763,236.446487 322.953552,233.581238 314.552582,231.363541 
	C308.731781,229.826935 302.702148,229.081253 296.313385,227.959976 
	C299.731567,224.768661 303.602203,221.599533 307.738251,218.233093 
z"/>
<path fill="#2FA4FF" opacity="1.000000" stroke="none" 
	d="
M362.998779,257.001221 
	C364.540588,255.561813 366.155853,254.191605 367.599182,252.659439 
	C368.615417,251.580673 369.397949,250.281784 370.641174,249.039505 
	C372.056335,264.050568 367.882935,277.953979 361.495148,291.313965 
	C360.476074,293.445312 358.577148,295.155945 356.928802,297.774658 
	C356.848877,298.978241 356.925140,299.468231 357.001404,299.958221 
	C357.001404,299.958221 356.974030,299.975189 356.622803,299.871948 
	C354.178680,301.604279 351.995514,303.347534 350.008423,305.291260 
	C340.962982,314.139191 330.821106,321.321564 319.000458,326.003265 
	C320.192505,322.307861 320.832214,318.293182 322.731995,315.006927 
	C324.630310,311.723206 327.789551,309.168457 330.851257,306.455322 
	C332.652313,307.324097 334.858551,308.936981 335.212341,308.600739 
	C339.222565,304.789429 345.397339,302.733459 346.255951,296.026276 
	C346.503357,296.026031 346.998169,296.024933 347.089478,296.189026 
	C347.452209,296.235840 347.723663,296.118500 348.143799,295.697418 
	C357.424652,284.216492 361.643158,271.157166 362.998779,257.001221 
z"/>
<path fill="#92D0FF" opacity="1.000000" stroke="none" 
	d="
M318.792847,326.286926 
	C330.821106,321.321564 340.962982,314.139191 350.008423,305.291260 
	C351.995514,303.347534 354.178680,301.604279 356.616394,299.886719 
	C354.418579,303.475372 351.875916,306.946014 349.203033,310.720947 
	C343.138184,315.715118 337.203552,320.405060 331.071869,325.340790 
	C323.090881,328.964355 315.331238,332.400024 307.504608,335.675751 
	C305.728424,336.419189 303.720001,336.607788 301.138031,336.914856 
	C299.996094,335.863800 299.534637,334.949677 299.073151,334.035522 
	C305.577179,331.547211 312.081207,329.058899 318.792847,326.286926 
z"/>
<path fill="#4BB2FF" opacity="1.000000" stroke="none" 
	d="
M362.996033,256.611603 
	C361.643158,271.157166 357.424652,284.216492 347.894531,295.701050 
	C347.496582,296.008453 346.998169,296.024933 346.998169,296.024933 
	C346.998169,296.024933 346.503357,296.026031 345.897186,295.953796 
	C344.859192,294.589569 344.427338,293.297546 343.995483,292.005554 
	C352.133972,281.091156 357.551544,268.907898 360.927582,255.376587 
	C361.162018,254.141327 361.367340,253.288513 361.572632,252.435699 
	C361.898376,252.429489 362.224121,252.423279 362.549866,252.417068 
	C362.697693,253.685379 362.845490,254.953674 362.996033,256.611603 
z"/>
<path fill="#4BB2FF" opacity="1.000000" stroke="none" 
	d="
M298.700439,334.154541 
	C299.534637,334.949677 299.996094,335.863800 300.731628,336.958740 
	C295.625031,338.422913 290.303406,340.215912 284.847351,340.847839 
	C279.272919,341.493469 273.566528,340.999695 267.446442,341.006012 
	C266.985901,340.262360 266.996796,339.517426 267.022888,338.401184 
	C274.095734,337.376923 281.153412,336.723938 288.602356,336.005280 
	C292.105011,335.384216 295.216370,334.828857 298.700439,334.154541 
z"/>
<path fill="#3FA0FF" opacity="1.000000" stroke="none" 
	d="
M343.695374,292.154785 
	C344.427338,293.297546 344.859192,294.589569 345.649780,295.954041 
	C345.397339,302.733459 339.222565,304.789429 335.212341,308.600739 
	C334.858551,308.936981 332.652313,307.324097 331.154358,306.308868 
	C335.133270,301.435486 339.264282,296.869781 343.695374,292.154785 
z"/>
<path fill="#92D0FF" opacity="1.000000" stroke="none" 
	d="
M357.314423,299.869568 
	C356.925140,299.468231 356.848877,298.978241 356.860718,298.113098 
	C357.175018,298.418976 357.401215,299.099945 357.314423,299.869568 
z"/>
<path fill="#1AB6F2" opacity="1.000000" stroke="none" 
	d="
M224.316193,237.026276 
	C225.444702,230.141739 226.933899,223.298843 228.690994,216.196045 
	C231.712830,217.290329 234.466766,218.644501 237.608734,219.997803 
	C236.372498,224.669083 234.807831,229.363037 233.107880,234.007462 
	C231.271667,239.024155 229.445496,239.644379 224.316193,237.026276 
z"/>
<path fill="#7AECA2" opacity="1.000000" stroke="none" 
	d="
M353.029846,169.259186 
	C353.205841,167.871140 353.574158,166.784424 353.996948,165.356873 
	C357.923828,159.378616 359.884979,153.145905 358.555145,146.390045 
	C357.212860,139.571198 352.604736,135.948654 345.405792,134.959152 
	C344.353088,134.814484 343.375763,134.121399 342.195984,133.347626 
	C350.946899,132.095459 358.647339,134.299973 364.601257,141.866638 
	C367.813904,152.194443 364.148438,161.216370 359.911804,170.502075 
	C357.304749,172.088959 354.935486,172.504120 353.029846,169.259186 
z"/>
<path fill="#1BFBA8" opacity="1.000000" stroke="none" 
	d="
M309.003967,143.402863 
	C307.811249,144.090500 306.615967,144.416122 305.083557,144.921082 
	C299.758026,145.759827 294.769684,146.419250 289.331573,147.044281 
	C295.340973,144.696884 301.800140,142.383911 308.644135,140.112808 
	C309.019806,141.116745 309.010590,142.078796 309.003967,143.402863 
z"/>
<path fill="#46E886" opacity="1.000000" stroke="none" 
	d="
M350.356842,170.927917 
	C350.494659,170.332321 350.950867,169.699448 351.728577,169.018677 
	C351.591827,169.610748 351.133514,170.250687 350.356842,170.927917 
z"/>
<path fill="#3FA0FF" opacity="1.000000" stroke="none" 
	d="
M347.089478,296.189026 
	C346.998169,296.024933 347.496582,296.008453 347.745850,296.004822 
	C347.723663,296.118500 347.452209,296.235840 347.089478,296.189026 
z"/>
</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
